import Layout from "../components/layout"
import Hero from "../components/hero"
import React, { Component } from "react"
import BookingForm from "../components/booking-form"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"

const TestimonialsPage = () => {
  const data = useStaticQuery(graphql`
    query TestimonialQuery {
      exteriorImage: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/exterior-minified-sizereduce" }
      ) {
        secure_url
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Testimonials"
        description="Pivot Painters - Chicago Painting Service Testimonials"
      />
      <Hero name="TESTIMONIALS" src={data.exteriorImage.secure_url} />
      <div className="testimonialContainer">
        <div className="row">
          <div className="review">
            <div className="fiveStars">
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i>
            </div>
            <p>
              They did a great job! Lovely attention to detail and we are very
              pleased with how the paint turned out. I would recommend them
              highly.
            </p>
            <p>-Brian Putka</p>
          </div>
          <div className="review">
            <div className="fiveStars">
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i>
            </div>
            <p>
              I have built two homes and have done a huge renovation on another.
              I have worked with numerous tradesmen over the years. Pivot
              Painters did a stellar job! They arrived on time, very pleasant to
              have in my home and were very clear and fair in their pricing. We
              will definitely hire them for our next big project!
            </p>
            <p>-Jennifer Warden</p>
          </div>
          <div className="review">
            <div className="fiveStars">
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i>
            </div>
            <p>
              {" "}
              They are great!! They always there on time. Very dedicated, you
              feel like they do the job for their own house trying to get the
              best result possible! Manny and the team are awesome. I have
              nothing to say but highly recommend them for your project.
            </p>
            <p>-Mohammed Osman</p>
          </div>
        </div>
        <div className="row">
          <div className="review">
            <div className="fiveStars">
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i>
            </div>
            <p>
              I'm very pleased with the re-staining of my cabinets. The workers
              were very diligent and courteous. Willing to go the extra mile, to
              make sure I was satisfied with the color of the stain. The owner
              was able to get the work done in the time frame I asked for. I
              would highly recommend this crew! - R.Dell
            </p>
            <p>-Rhonda Dell</p>
          </div>
          <div className="review">
            <div className="fiveStars">
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i>
            </div>
            <p>
              We are very pleased we chose Pivot Painters (Manny). They did our
              living room, Dining room , Kitchen, Halls and all the ceilings.
              All one open area with vaulted ceilings. They went above and
              beyond. The whole job turned out better than expected. Beautiful
              work. I will recommend them to everyone. We will call him again.
            </p>
            <p>-Bruce Dolence</p>
          </div>
          <div className="review">
            <div className="fiveStars">
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i>
            </div>
            <p>
              I was so pleased with the painting. Manny and his team worked in
              the promised time frame, were polite, repaired walls, went above
              and beyond. I highly recommend Pivot Painters
            </p>
            <p>-Tamara Lentini</p>
          </div>
        </div>
        <div className="row">
          <div className="review">
            <div className="fiveStars">
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i>
            </div>
            <p>
              Hard working, paid attention to details, and did a two day job in
              one day! Awesome! Manny and his team are friendly and clean up
              nicely!
            </p>
            <p>-Lisa Dempsey</p>
          </div>
          <div className="review">
            <div className="fiveStars">
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i>
            </div>
            <p>
              The crew did a great job. Manny scheduled around our needs and
              finished sooner than expected. Looks great!
            </p>
            <p>-Susan Barnes</p>
          </div>
          <div className="review">
            <div className="fiveStars">
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i>
            </div>
            <p>
              They did an excellent job and I would definitely refer them! Clean
              up good.
            </p>
            <p>-Candy Hardy</p>
          </div>
        </div>
        <div className="row">
          <div className="review">
            <div className="fiveStars">
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i>
            </div>
            <p>
              I am really satisfied with the work done. They delivered an
              excellent job and I would highly recommend them.
            </p>
            <p>-Yailyn Cordova</p>
          </div>
          <div className="review">
            <div className="fiveStars">
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i>
            </div>
            <p>
              Would hire these guys again. Straight to the work, great
              communication and took their time painting my master bedroom and
              kitchen. Please give these guys a try!
            </p>
            <p>-Mike Lima</p>
          </div>
          <div className="review">
            <div className="fiveStars">
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i>
            </div>
            <p>
              They arrived on time and are professional. Our furniture and
              carpeting were covered with clean tarps. We had damage to walls
              from the movers when we moved into our home recently. Manny was
              meticulous with the repairs. After the painting was …More
            </p>
            <p>-Andy Markey</p>
          </div>
        </div>
        <BookingForm></BookingForm>
      </div>
    </Layout>
  )
}

export default TestimonialsPage
